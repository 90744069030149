/****************** General ************************/
/***************************************************/
/*horizontal ruler*/
.divider,
.cms_divider,
.cms_item_divider,
.item_divider,
.separator,
#services_module.list .list_item .footer,
#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	height: 1px !important;
	margin-top: 1.5rem !important;
	margin-bottom: 1.5rem !important;
	background-color: #c9c6c4 !important;
	border-top: 0 none !important;
	border-bottom: 0 none !important;
}

.collection > .node {
	border-top: 1px solid #c9c6c4;
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

.section_separator {
	border-bottom: 1px solid #c9c6c4;
}

/* dates */
#alerts_module.list .title .date,
#events_module.cms_list .cms_date,
#feeds_module.cms_list .cms_list_item .cms_date h4,
#feeds_module.cms_entity .cms_date h4,
#jobs_module.cms_list .cms_list_item .cms_date h3,
#news_module.cms_list article time,
#news_module.cms_entity .cms_date h3,
#photoalbums_module.cms_list .cms_list_item .cms_number_of_images, /* this is not a date but styled as one */
.posts-node .posts-field.posts-field-posted p,
#video_module.cms_list .cms_list_item .cms_date,
#video_module.cms_entity .cms_date h3,
#reservations_module.entity .metadata2 {
	font-size: 1rem;
	line-height: 1.7rem;
	font-weight: 500;
	color: #2e2b2b;
}

/* ui form tags */
.ui-form-error {
	display: none;
}

.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem !important;
	margin-bottom: 0.5rem !important;
	color: #0a706b !important;
	font-size: 1.2rem !important;
	line-height: 1.5rem;
	font-weight: 500 !important;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #cc0000 !important;
	font-style: italic;
	font-family: Arial, sans-serif;
}

.required_information {
	font-family: Arial, sans-serif;
	color: #cc0000;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #878787;
	border-radius: 0px;
	padding: 8px 12px;
	font-size: 0.875rem;
	line-height: 1.42857143;
	color: #333333;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #878787;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-note {
	line-height: 1rem;
	margin-top: 5px;
}

@media (max-width: 991px) {
	.ui-form-fields {
		padding: 0px;
	}

	.ui-form-label {
		position: static;
		padding: 0px;
		height: auto;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-input {
		width: 100%;
	}

	.ui-form-input input[type="text"],
	.ui-form-input input[type="tel"],
	.ui-form-input input[type="password"],
	.ui-form-input input[type="email"],
	.ui-form-input input[type="file"],
	.ui-form-input select,
	.ui-form-input textarea,
	.ui-form-fields .select2-container,
	.ui-form-fields .select2-container-multi,
	.ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}

	.ui-form-panel {
		padding: 0 !important;
	}

	.ui-form-buttons {
		text-align: left;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
		width: auto;
	}
}

/* pagination */
.pagination-wrapper .pagination-row td span[style="color:#999999;font-weight:bold;"] {
	color: #757575 !important;
	cursor: not-allowed;
}

/****************** Accounts ************************/
/***************************************************/
/*accounts*/
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 365px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_buttons {
	margin-top: 0;
}

#account_module.form .form_container input[type="password"] {
	margin-bottom: 10px;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid #c9c6c4;
	margin: 20px 0;
	padding-bottom: 7px;
	color: #0a706b;
	font-size: 1.714rem;
	line-height: 2.414rem;
	font-weight: 600;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #cc0000;
	font-style: italic;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 767px) {
	#account_addresses .address_section td {
		display: block;
		width: 100%;
	}

	#account_addresses .address_section td br {
		display: none;
	}

	#account_addresses .address_section td a + br {
		display: block;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/****************** Alerts ************************/
/*************************************************/
#alerts_module.list h2 {
	margin-top: 0;
	margin-bottom: 10px;
}

#alerts_module.list .date {
	margin-bottom: 5px;
	display: inline-block;
}

#alerts_module_subscribe_form.form .form_buttons {
	margin-top: 0;
}

#alerts_module_subscribe_form h3 {
	color: #2d3047;
	font-size: 1.143rem;
	line-height: 1.743rem;
	text-transform: lowercase;
	font-weight: 600;
	margin-top: 0.25rem;
	margin-bottom: 0.5rem;
}

#alerts_module_subscribe_form h3:first-letter {
	text-transform: uppercase;
}

#alerts_module_subscribe_form .field {
	padding-top: 0 !important;
}

#alerts_module_subscribe_form td {
	vertical-align: top;
	font-size: 0.875rem;
}

#alerts_module_subscribe_form.form .item_divider {
	margin-bottom: 0.75rem !important;
	margin-top: 2rem !important;
	height: 3px !important;
	background-color: #c9c6c4 !important;
}

#alerts_module_subscribe_form.form .form_item .field label,
#alerts_module_subscribe_form.form .form_item .field span,
#alerts_module_subscribe_form.form .form_item .field input,
#alerts_module_subscribe_form.form .form_item .field select,
#alerts_module_subscribe_form.form .form_item .label,
#alerts_module_subscribe_form.form .form_item.required .label {
	font-size: 1rem;
	width: 100% !important;
}

#alerts_module_subscribe_form.form .form_item .field small {
	display: block;
}

#alerts_module.form .moduleform .form_item td {
	padding-bottom: 10px;
}

#alerts_module.form .moduleform .form_item .field small {
	display: block;
}

@media (max-width: 767px) {
	#alerts_module.form .moduleform .form_item td {
		display: block;
		width: 100% !important;
		text-align: left;
		padding-left: 0;
	}

	#alerts_module.form .moduleform .form_item .label {
		padding-bottom: 5px;
	}

	#alerts_module.form .moduleform .form_buttons {
		text-align: left;
		margin-top: 0;
	}
}

/***************** Collections *******************/
/*************************************************/
.module-collections.controller-pages-collections .archive-selected {
	border-bottom-color: transparent;
	font-weight: 400;
	color: #06413d;
}

.module-collections.controller-pages-collections .archive-selected:hover {
	border-bottom-color: transparent;
	color: #06413d;
}

.module-collections.controller-pages-collections .files-wrapper {
	margin-left: 0rem;
}

/****************** Events *********************/
/***********************************************/
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .location-label-events-module {
	font-weight: 500;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
	font-size: 1rem;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	padding-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .event-category-label {
	margin-bottom: 10px;
}

#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/****************** Files *********************/
/***********************************************/
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1rem 0px 0px 0px;
	padding: 1rem 0px 0px 0px;
	border-top: 1px solid #c9c6c4;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	cursor: pointer;
}

.module-files.controller-pages-files .ui-form-label span {
	font-weight: 400;
}

.module-files.controller-pages-files .cms_buttons {
	text-align: center;
}

.select2-container .select2-choice > .select2-chosen {
	line-height: 19px;
}

/* sidebar file filtering */
.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col .box.filter .filter-row.ui-form-buttons button {
	margin-top: 0;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	width: auto;
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #878787;
	background: none;
	padding: 8px 12px;
	height: auto;
	background-color: #fff;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

.right-side-col .select2-container .select2-choice .select2-arrow b {
	background-position: 0 9px !important;
}

.right-side-col .select2-dropdown-open .select2-choice .select2-arrow b {
	background-position: -18px 9px !important;
}

.field--file--title button.bookmark {
	padding: 0 !important;
	margin: 0;
	min-width: 0;
}

.field--file--title button.bookmark:hover {
	background-color: transparent !important;
	cursor: pointer;
}

.field--category--title a,
.field--file--title a {
	display: inline;
}

/****************** FAQs *********************/
/***********************************************/
#faqs_module .cms_title h3 {
	margin-top: 0;
}

#faqs_module .cms_list_item > ul {
	margin: 0;
	list-style-type: disc;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_list_item .description,
#faqs_module.cms_list .cms_list_subitem .description {
	padding: 0 0 10px;
	list-style-type: none;
}

/****************** Forums *********************/
/***********************************************/
#forums_module #posts table,
#forums_module #boards table,
#forums_module #topics table,
#forums_module #posts .post_footer {
	background: #fff;
	border: 0px;
}

#forums_module #boards td,
#forums_module #topics td,
#forums_module #posts td {
	border-color: #c9c6c4;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

#forums_module #boards tr td:last-child,
#forums_module #topics tr td:last-child,
#forums_module #posts tr td:last-child,
#forums_module #topics th {
	border-color: #c9c6c4;
}

#forums_module h2 {
	color: #0a706b;
	margin-top: 0px;
}

#forums_module #posts table td.actions a {
	height: auto;
}

#forums_module #topics tfoot tr th {
	padding-top: 1.5rem;
}

#forums_module.form.advanced-search .form_buttons {
	text-align: left;
}

#forums_module #forum-search #forum-search-container input {
	border: 1px solid #ccc;
	cursor: pointer;
}

#forums_module .back_to a,
#forums_module .header-right a {
	font-weight: 400;
}

#forums_module .image-enlarge {
	color: #2e2b2b;
	cursor: pointer;
}

#forums_module .close-modal-button {
	line-height: 18px;
}

#forums_module #posts table td.actions a {
	color: #757575;
	cursor: pointer;
}

@media (max-width: 991px) {
	#forums_module #topics tr td.poster_info,
	#forums_module #posts tr td.poster_info {
		padding-top: 1.5rem;
	}

	#forums_module #topics tr.moderator_actions td {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	#forums_module #posts tfoot tr td {
		padding-top: 1.5rem;
	}
}

/******************** Glossary *********************/
/***************************************************/
#glossary .glossary-term-wrapper strong {
	font-weight: 600;
}

/****************** Jobs ***********************/
/***********************************************/
#jobs_module.cms_list .cms_list_item {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#jobs_module.cms_list .cms_list_item .cms_footer {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-color: #c9c6c4;
}

#jobs_module.cms_form .cms_label,
#jobs_module.cms_form .cms_field,
#jobs_module.cms_form .cms_required {
	background: none;
	font-weight: normal !important;
	vertical-align: top;
}

#jobs_module.cms_form .cms_divider {
	height: 0px !important;
}

#jobs_module.cms_form .cms_form_button {
	padding: 0.375rem 1rem;
}

#jobs_module.cms_list .cms_list_item .cms_date h3 {
	margin-top: 0;
}

/* sidebar job search filtering */
.right-side-col .box #frmDepartments {
	margin-top: 0.75rem;
}

.right-side-col .box #frmDepartments #Department {
	width: 100%;
}

.right-side-col .box #frmDepartments button[type="submit"].primary {
	padding: 9px 10px !important;
	display: inline;
}

@media (max-width: 991px) {
	#jobs_module.cms_form .cms_field input[type="text"],
	#jobs_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#jobs_module.cms_form .cms_label,
	#jobs_module.cms_form .cms_required {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
		padding-top: 7px;
	}

	#jobs_module.cms_form .cms_required {
		width: 20px;
	}
}

/******************** Links ************************/
/***************************************************/
.box.tags a {
	border-bottom-color: transparent;
}

/****************** Locations *******************/
/***********************************************/
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: #c9c6c4;
}

#locations_module_display_view_contentbox {
	text-align: left;
}

#locations_module.entity {
	overflow: hidden;
}

/****************** News ***********************/
/***********************************************/
.box.archive {
	overflow: hidden;
}

#news_module.cms_list article {
	padding-bottom: 1px;
}

#news_module.cms_list .cms_categories_section .cms_category_item .image {
	margin-bottom: 10px;
}

#news_module.cms_list .cms_metadata1.cms_title h3 {
	margin-top: 0;
}

#news_module.cms_list article h2 {
	padding-top: 0;
}

.news_module.cms_category_list.header {
	margin-bottom: 20px;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0;
}

#news_module.cms_entity .quaternary_title {
	margin-top: 0;
}

.archive_links {
	overflow: hidden;
	margin-bottom: 0.75rem;
}

.archive_links a,
.archive_links a.selected {
	padding-bottom: 0;
}

@media (max-width: 991px) {
	#news_module.cms_entity .cms_content .image {
		float: none !important;
		margin-left: 0;
		height: auto !important;
	}

	#news_module.cms_list .cms_categories_row {
		margin-bottom: 20px;
	}
}

/****************** Partners ********************/
/***********************************************/
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
	margin-bottom: 0rem;
	padding-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_list_item table td.image a {
	border-bottom-width: 0;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	margin-right: 0px;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/****************** Photo Albums ****************/
/***********************************************/
#photoalbums_module.cms_list .cms_list_item table td.cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_list_item table td.cms_image a img {
	max-width: 150px;
}

#photoalbums_module.cms_list .cms_list_item .cms_title h3 {
	padding-top: 0;
}

#photoalbums_module.cms_entity .js-with-lightbox-magnifier {
	overflow: hidden;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#photoalbums_module.cms_list .cms_list_item table td.cms_image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/******************* Polls *********************/
/***********************************************/
#polls #pollResult .line {
	background-color: #c9c6c4;
	height: 4px;
}

#polls .session_message.error p {
	color: #fff;
	display: block;
}

#polls .ui-form-container .ui-form-legend {
	display: block;
	height: auto;
	margin-top: 1.5rem !important;
	margin-bottom: 0rem !important;
	font-size: 1.5rem !important;
	line-height: 2.2rem !important;
	font-weight: 600 !important;
}

#polls .ui-form-legend span.icon {
	display: none;
}

/******************* Posts *********************/
/***********************************************/
.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background-color: #2d3047;
	margin-top: 1.5rem;
	margin-bottom: 1rem;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid #c9c6c4;
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	min-width: 0;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

/* sidebar posts */
.posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.posts-blocks.posts-blocks-rss-feeds .posts-blocks-rss-feed a {
	border-bottom: 0px;
}

.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.posts-blocks.posts-blocks-authors .posts-blocks-author,
.posts-blocks.posts-blocks-categories .posts-blocks-category,
.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 0.75em;
}

.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 41px;
}

.posts-blocks-tags a {
	border-bottom-width: 0;
}

/********** Products (not e-commerce) **********/
/***********************************************/
#products_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#products_module.cms_entity .cms_content .image {
	margin-right: 15px;
	margin-left: 0px;
}

@media (max-width: 991px) {
	#products_module.cms_list .cms_list_item.left,
	#products_module.cms_list .cms_list_item.right {
		width: 100%;
		display: block;
		padding-left: 0px;
		padding-right: 0px;
	}

	#products_module.cms_list .cms_list_item .tile_description .image {
		float: none;
		display: block;
		padding-left: 0px;
	}

	#products_module.cms_list .cms_list_item .cms_title {
		padding: 0px;
	}

	#products_module.cms_list .cms_list_item {
		padding-bottom: 1.5rem !important;
	}

	#products_module.cms_list .cms_item_divider {
		margin-top: 0px !important;
	}
}

/******************** Rates ********************/
/***********************************************/

/***************** Resources *******************/
/***********************************************/
#moduleDocuments_module.cms_list .cms_items .cms_item .cms_item_description {
	margin-top: 5px;
}

.moduleDocuments-rss-feed-link {
	border-bottom-width: 0 !important;
}

.box.tools a[href^="?tag"] {
	border-bottom-width: 0;
}

/****************** RSS Feeds *********************/
/*************************************************/
#feeds_module.cms_list .cms_title h3,
#feeds_module.cms_list .cms_date h4 {
	margin-top: 0px;
	margin-bottom: 10px;
	padding-top: 0px;
}

#feeds_module.cms_list .abstract {
	padding: 0px;
}

#feeds_module.cms_entity h4,
#feeds_module.cms_entity h3 {
	margin-top: 0;
}

#feeds_module.cms_entity .cms_content {
	padding-top: 0;
}

/***************** Services ********************/
/***********************************************/
#services_module.list .list_item .title h3 {
	margin-top: 0px;
	padding-top: 0px;
}

@media (max-width: 767px) {
	#services_module.entity .image {
		float: none;
		margin-bottom: 15px;
	}
}

/*************** Staff Directory ***************/
/***********************************************/
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .cms_title {
	font-weight: 500;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/*************** Submission Forms ***************/
/************************************************/
#submissionforms_module.cms_form .cms_divider {
	display: none;
}

#submissionforms_module.cms_form .cms_required em {
	display: none;
}

#submissionforms_module.cms_form .required_information em {
	font-family: Arial, sans-serif;
	color: #cc0000;
}

#submissionforms_module.cms_form tr[data-required="true"] .cms_label label:after,
#submissionforms_module.cms_form tr[data-required="true"] .cms_label div:after {
	content: "*";
	color: #cc0000;
	padding-left: 5px;
	font-style: italic;
	font-size: 1.2rem;
	font-family: Arial, sans-serif;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding: 0px 27px;
}

#submissionforms_module.cms_form .cms_steps_wrapper span a {
	border-bottom: 0 none;
}

#submissionforms_module.cms_form .sigWrapper.current {
	height: 102px;
	width: 100%;
	max-width: 302px;
	display: block !important;
	border-color: #878787;
}

#submissionforms_module.cms_form .sigPad input {
	display: block;
	border-color: #878787;
}

#submissionforms_module.cms_form .cms_field,
#submissionforms_module.cms_form .cms_required,
#submissionforms_module.cms_form .cms_label {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .formSection {
	margin-bottom: 15px;
}

#submissionforms_module.cms_form .cms_field .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 0 none;
	border-top: 1px solid #c9c6c4;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 40%;
	transform: translate(0px, -60%);
}

#submissionforms_module .ui-form-field-subtitle .ui-form-label label,
#submissionforms_module .ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: 400;
	color: #2e2b2b;
}

#submissionforms_module .ui-form-label span {
	color: #2e2b2b;
	font-weight: 400;
}

#submissionforms_module .ui-form-label em {
	color: #cc0000;
	font-style: italic;
	font-family: Arial, sans-serif;
}

#submissionforms_module.cms_form .cms_field.currency::before,
.responsive #submissionforms_module.cms_form .cms_field.currency::before {
	top: 26%;
}

@media (max-width: 991px) {
	.module-forms.confirmation td {
		display: block;
		width: 100%;
	}
}

@media (max-width: 767px) {
	#submissionforms_module.cms_form .cms_required,
	#submissionforms_module.cms_form .cms_label {
		padding-bottom: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label::after {
		content: "";
		padding-left: 0;
	}

	#submissionforms_module tr[data-required="true"] .cms_label label::after,
	#submissionforms_module tr[data-required="true"] .cms_label div::after {
		content: "*";
		color: #cc0000;
		padding-left: 5px;
		font-family: Arial, sans-serif;
	}

	#submissionforms_module.cms_form .cms_field.currency::before,
	.responsive #submissionforms_module.cms_form .cms_field.currency::before {
		top: 37%;
		left: 4px;
	}
}

/*************** Success Stories ***************/
/***********************************************/
#stories_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	padding-top: 0px;
}

#stories_module.cms_list .cms_list_item .image {
	max-width: 200px;
}

@media (max-width: 991px) {
	#stories_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/**************** Testimonials ******************/
/***********************************************/
#testimonials_module.cms_list .cms_list_item .cms_bottom,
#testimonials_module.cms_list .cms_list_item .cms_content {
	display: inline-block;
	width: 100%;
}

#testimonials_module.cms_list .cms_list_item .cms_content .testimonial-link {
	border-bottom: 0px;
}

/******************* Videos ********************/
/***********************************************/
#video_module.cms_list .cms_video {
	padding-right: 20px;
}

#video_module.cms_entity .cms_metadata1.cms_date h3 {
	margin-top: 15px;
}

#video_module.cms_list .cms_metadata1.cms_title {
	padding-top: 0;
}

#video_module.cms_list .cms_list_item {
	padding-bottom: 1.5em;
	margin-bottom: 1.5em;
	border-bottom: 1px solid #c9c6c4;
}

#video_module.cms_list .cms_list_item:last-of-type {
	padding-bottom: 0;
	margin-bottom: 0;
	border-bottom: 0 none;
}

.sidebar-section-wrap.box.categories .content a {
	display: inline-block;
	margin-bottom: 0.875rem;
}

/**************** White Papers ******************/
/************************************************/
#whitepapers_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
	padding-top: 0px;
}

@media (max-width: 991px) {
	#whitepapers_module.cms_list .cms_list_item .image {
		padding: 0px 0px 10px 0px;
		display: block;
		float: none;
	}
}

/****************** Mailing Lists ************************/
/*********************************************************/
#mailinglists-module.form .form_subheading {
	border-bottom: 1px solid #c9c6c4;
	margin: 12px 0 20px;
}

#mailinglists-module .moduleform .required_message span::before,
#mailinglists-module .moduleform .form_item.required .label label::after {
	font-family: Arial, sans-serif;
}

@media (max-width: 767px) {
	#mailinglists-module.form .form_buttons {
		text-align: left;
	}
}

/****************** Newsletters ********************/
/***************************************************/
#newsletters_module.list h3 {
	padding-top: 0;
	margin-top: 0;
}

#newsletters_module.form .instructions {
	font-weight: 500;
}

@media (max-width: 991px) {
	#newsletters_module.form .form_item .label,
	#newsletters_module.form .form_item.required .label {
		width: auto;
		display: inline-block;
		text-align: left;
		padding-bottom: 5px;
	}

	#newsletters_module.form .form_item.required .label {
		background-image: url('/core/public/shared/assets/images/required.gif');
		background-repeat: no-repeat;
		background-position: right center;
		padding-right: 20px;
	}

	#newsletters_module.form .form_item .label label {
		display: inline-block;
	}

	#newsletters_module.form .form_item .field {
		width: 100%;
		display: block;
		padding-left: 0;
		padding-bottom: 15px;
	}

	#newsletters_module.form .form_item.required .field {
		background: transparent none;
	}

	#newsletters_module.form .form_item .field table tr td {
		vertical-align: top;
	}

	#newsletters_module.form .form_buttons {
		text-align: left;
	}

	#newsletters_module.form .form_item input[type="text"] {
		width: 100% !important;
	}
}

/**************** Classifieds ******************/
/***********************************************/
.classifieds-collection .classifieds-node .classifieds-field h2 {
	margin-top: 0px;
	margin-bottom: 0px;
	padding-top: 0px;
}

/******************** Ideas *********************/
/************************************************/
.ideas-collection .ideas-node .ideas-field-title h2 {
	margin-top: 0px;
	padding-top: 0px;
}

/******************** Kudos *********************/
/************************************************/
.kudos-collection .kudos-node .kudos-field-title h2 {
	margin-top: 0px;
	padding-top: 0px;
}

.kudos-field.kudos-field-users strong {
	font-weight: 500;
}

.kudos-node .kudos-comments-title {
	background: #2d3047;
	margin-bottom: 1em;
}

.kudos-node .kudos-comment-author {
	font-size: 0.8rem;
}

.kudos-node #kudos-comments .kudos-comment-form-required-info textarea {
	width: 100%;
}

.kudos-node .kudos-comment {
	border-bottom: 1px dotted #c9c6c4;
	padding-bottom: 1em;
	padding-top: 0;
	margin-bottom: 1em;
}

.kudos-node .kudos-comments .ui-form-fieldset legend.ui-form-legend {
	margin-top: 0 !important;
}

#post-add-comment .ui-form-fields {
	padding: 0 0 0 160px;
}

@media (max-width: 991px) {
	#post-add-comment .ui-form-fields {
		padding: 0;
	}
}

/**************** Reservations ****************/
/**********************************************/
.resource-collection .resource-option {
	background: none;
	padding: 0px;
	text-align: right;
}

.resource-collection .resource-option .select2-container--default {
	max-width: 100%;
}

.resource-collection .resource-option .select2-container--default .select2-selection--single {
	background-color: #fff;
	border: 1px solid #878787;
	border-radius: 0px;
}

.module-reservations .reservations-module .ui-form-input input[type="text"],
.module-reservations .reservations-module .ui-form-input select,
.module-reservations .reservations-module .ui-form-input textarea {
	max-width: 100%;
	padding: 8px 12px;
	font-size: 0.875em;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	margin-left: 0px !important;
	border: 1px solid #878787;
}

.module-reservations .reservations-module .ui-form-label {
	margin-left: 0px !important;
	margin-bottom: 4px !important;
}

.module-reservations .reservations-module .ui-form-legend-wrapper {
	width: 100%;
}

.module-reservations .reservations-module .ui-form-legend-wrapper .ui-form-legend {
	width: 100%;
	color: #ffffff !important;
	background: #2d3047;
	display: inline-block;
	height: auto;
	padding-top: 7px;
	padding-bottom: 7px;
}

.module-reservations .reservations-module .ui-form-legend span.icon {
	height: 24px;
	margin-left: 15px;
	margin-right: 10px;
}

.select2-container .select2-choice .select2-arrow {
	background: transparent;
	border-left-color: transparent;
}

.select2-container .select2-choice .select2-arrow b {
	background-position: 0 7px;
}

.select2-container .select2-choice > .select2-chosen {
	line-height: 30px;
}

@media (max-width: 991px) {
	.module-reservations .reservations-module .ui-form-input input[type="text"].hasDatepicker {
		width: calc(100% - 20px) !important;
	}

	.module-reservations .reservations-module .ui-datepicker-trigger {
		margin-top: 13px;
	}

	.module-reservations .ui-form-label {
		overflow: hidden;
	}
}

@media (max-width: 767px) {
	#reservations_module.entity .content td {
		display: block;
		width: 100%;
	}

	#reservations_module.entity .content .right {
		padding-left: 0;
		padding-top: 0;
		padding-bottom: 15px;
	}

	#reservations_module.entity .content .right p:last-of-type {
		margin-bottom: 0;
	}
}

/****************** Medical ************************/
/***************************************************/
/* accordion search */
.physician-search-accordion .search-reveal-trigger {
	margin-top: 10px !important;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .top-search-submit {
	min-width: 65%;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .form-reset {
	font-size: 0.9rem;
}

.physician-search-accordion .physician-search-wrap .physician-search-field .bottom-search-submit {
	margin-right: 0px;
}

.physician-search-accordion .physician-search-field .bottom-search-submit {
	background-color: #0a706b !important;
	margin: 0;
	cursor: pointer;
}

.physician-search-accordion .physician-search-field .bottom-search-submit:hover {
	background-color: #06413d !important;
	cursor: pointer;
}

.physician-search-accordion .physician-search-field label {
	display: block;
}

.physician-search-accordion .name-search-form .select2.select2-container.select2-container--default {
	width: 75% !important;
}

.physician-search-accordion.in-main-content-area.open {
	padding-bottom: 1.5em;
}

/* physicians */
#physicians-module.physicians-collection .node.physician-node {
	margin-top: 1.5rem;
	margin-bottom: 0;
	padding-top: 1.5em;
	border-bottom: 0 none;
}

#physicians-module.physicians-collection .node.physician-node:first-of-type {
	border-top: 0 none;
	margin-top: 0;
	padding-top: 0;
}

#physicians-module .field.field--phone .md-custom-heading.md-big-heading {
	font-size: 1.2rem;
}

#physicians-module.node .physician-profile-section,
#physicians-module .md-row.doctor-profile-section {
	border-top: 1px solid #c9c6c4 !important;
	margin-top: 1.5rem !important;
	padding-top: 1.5rem !important;
}

#physicians-module .md-row.md-patient-comment-block {
	background-color: hsla(30, 1%, 43%, 0.1) !important;
	font-family: inherit;
}

@media (max-width: 767px) {
	#physicians-module .md-physician-image {
		padding-right: 10px;
	}
}

/*clinics*/
#clinics-module.clinics-collection .md-location-list-h2 {
	margin-bottom: 0;
}

#clinics-module .facility-maps .link {
	display: inline-block;
}

@media (max-width: 479px) {
	#clinics-module .md-column-content-wrap {
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;
	}
}

/*greeting cards*/
#greetingcards-module .GreetingCardGeneralInfo .ui-form-fields {
	padding: 0 0 0 170px;
}

#greetingcards-module .GreetingCardGeneralInfo .ui-form-label {
	left: -170px;
	width: 170px;
}

@media (max-width: 991px) {
	#greetingcards-module .GreetingCardGeneralInfo .ui-form-fields {
		padding: 0;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .ui-form-label {
		margin-bottom: 5px;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field input,
	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field textarea {
		max-width: 100%;
	}

	#greetingcards-module .greeting-card-form-preview-wrapper .GreetingCardGeneralInfo .ui-form-fieldset .ui-form-field #field-roomNumber {
		width: 100% !important;
	}
}

/******************* Search ************************/
/***************************************************/
.collection.search-collection > .node.search-node {
	border-top: 1px solid #c9c6c4;
	margin: 1.5em 0 0 0;
	padding: 1.5em 0 0 0;
}

.collection.search-collection > .node.search-node:first-of-type {
	border-top: 0 none;
	padding: 0;
}

.collection.search-collection .search-field-title {
	line-height: 1.6rem;
}

input[type="search"] {
	border: 1px solid #ccc;
}

/****************** eCommerce **********************/
/***************************************************/
/*list view*/
#products_module.responsive .items .item-wrapper .item .item-description .metadata1.cms_title h3 {
	font-size: 1.375rem;
	margin-bottom: 10px;
}

#products_module.responsive a {
	border-bottom: 1px solid #797676;
}

#products_module.responsive a:hover {
	border-bottom: 1px solid transparent;
}

#products_module.responsive .item-image a,
#products_module.responsive .paginationView {
	border-bottom: 0 none;
}

#products_module.responsive .item-image a:hover,
#products_module.responsive .paginationView:hover {
	border-bottom: 0 none;
}

#products_module.responsive .item-old-price {
	color: #a3a3a3;
	font-weight: 400;
}

#products_module.responsive .item-sale-price {
	color: #cc0000 !important;
	font-weight: 700;
}

#products_module.responsive .item-sale-price .sale-price-label {
	display: none;
}

#products_module.responsive .category-header-column,
#products_module.responsive .category-header-column {
	width: 100%;
}

#products_module.responsive .item-old-price {
	color: #757575;
	font-weight: 400;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #757575;
	cursor: not-allowed;
}

/*details view*/
.product-details-info #product-container label {
	display: block;
	margin-top: 10px;
	margin-bottom: 3px;
}

#products_module.responsive.cms_entity .product-details-info-wrapper .product-details-info {
	padding: 15px;
}

.product-details-info #product-container br {
	display: none;
}

#products_module.responsive .add_to_cart_form .quantity_row .product-quantity-label {
	width: 25%;
}

#products_module.responsive .add_to_cart_form .contentbox_item {
	margin-top: 15px;
}

#products_module.responsive .contentbox_item .accepted-formats {
	word-wrap: break-word;
}

#products_module.responsive .viewMoreAttributeOptions,
#products_module.responsive .hideMoreAttributeOptions {
	width: auto;
}

#products_module.responsive .affiliate_box .affiliate_url {
	margin-bottom: 3px;
	display: inline-block;
	width: auto;
}

#products_module.responsive .availability_box {
	margin-bottom: 20px;
}

#products_module.responsive .availability_box .available,
#products_module.responsive .availability_box .unavailable {
	display: block;
}

#products_module.responsive .availability_box .available {
	color: #2f6b3a;
	font-weight: 500;
}

#products_module.responsive .availability_box .unavailable {
	color: #163a59;
	font-weight: 400;
}

#products_module.responsive label em,
#products_module.responsive .ui-form-label em {
	color: #cc0000;
	font-style: italic;
	font-family: Arial, sans-serif;
}

#products_module.responsive .required-fields-information em {
	color: #cc0000;
	font-style: italic;
	font-family: Arial, sans-serif;
}

#products_module.responsive.cms_form .preferred-contact-method-label.ui-form-label {
	font-weight: 400;
}

#products_module.responsive .tab-item-content .product-review {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 1.5em;
	padding-bottom: 1.5em;
	border-bottom: 1px solid #c9c6c4;
}

#products_module.responsive .tab-item-content .product-review:last-of-type {
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 0;
	padding-bottom: 0;
	border-bottom: 0 none;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-answer {
	margin-left: 0;
}

#products_module.responsive.cms_entity .tab-item-content .product-question-have-a-question {
	border-top: 1px solid #c9c6c4;
}

#products_module.responsive .add_to_cart_form .cart-estimate-button {
	width: 100%;
}

#products_module.responsive.cms_entity .tab-item-content .separator {
	margin-bottom: 1.5em;
	margin-top: 1.5em;
	border-top: 1px solid #c9c6c4;
}

#products_module.responsive.cms_entity .related-items .related-items-title,
#products_module.responsive.cms_entity .recently-viewed-items .recently-viewed-items-title {
	color: #0a706b;
	font-size: 1.7rem;
	line-height: 2.3rem;
	font-weight: 600;
}

@media (min-width: 481px) and (max-width: 720px) {
	#products_module.responsive .add_to_cart_form .cart-estimate-button {
		width: 47%;
		margin-bottom: 5px;
		margin-right: 2%;
	}
}

@media (max-width: 720px) {
	#products_module.responsive.cms_form .ui-form-field {
		width: 100% !important;
	}

	#products_module.responsive.cms_form .ui-form-buttons {
		text-align: left;
	}
}

/*account orders*/
@media (max-width: 767px) {
	#account_module.entity #account_orderhistory table td {
		display: block;
		width: 100%;
		padding-bottom: 15px;
		text-align: left !important;
	}
}

/*checkout views*/
#s-customer {
	overflow: hidden;
}

#s-customer #s-sign-in .ui-form-label {
	height: auto;
	padding-bottom: 0;
}

#s-customer #s-sign-in .ui-form-label em {
	color: #cc0000;
	font-style: italic;
	font-family: Arial, sans-serif;
}

#s-checkout .ui-form-label label em,
.ui-form-field-subtitle .ui-form-label span em {
	color: #cc0000;
	font-style: italic;
	font-family: Arial, sans-serif;
}

#products_module.responsive.cms_form .required-fields-information em {
	font-family: Arial, sans-serif;
}

.ui-form-field-subtitle .ui-form-label span {
	font-size: 100%;
	font-weight: normal;
	color: #363a3e;
}

#s-cart .w-col.s-summary #s-estimate-shipping button {
	margin: 0;
	white-space: normal;
}

#s-checkout fieldset {
	border: 0 none;
}

#s-order-confirmation {
	overflow: hidden;
}

@media (max-width: 991px) {
	#s-cart #s-promotions.s-expanded .s-form-container .s-fields.s-label,
	#s-cart #s-promotions.s-expanded .s-form-container .s-fields.s-form {
		float: none;
	}
}

@media (min-width: 767px) {
	#s-checkout fieldset .ui-form-text > input#ShippingPhone[type="text"],
	#s-checkout fieldset .ui-form-text > input#BillingPhone[type="text"] {
		width: 189px;
	}
}

/****************** Content Boxes *******************/
/***************************************************/
/* alerts */
#alerts_module.homepage_contentbox ul {
	list-style-type: none;
	padding-left: 0;
}

#alerts_module.homepage_contentbox.list_alerts .alerts > a:link {
	display: none;
}

#alerts_module.homepage_contentbox.list_alerts .alerts li a:link,
#alerts_module.homepage_contentbox.list_alerts .alerts li a:active,
#alerts_module.homepage_contentbox.list_alerts .alerts li a:visited {
	margin-bottom: 0;
	padding-right: 0;
	padding-top: 0.125em;
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#alerts_module.homepage_contentbox.list_alerts .alerts li {
	margin-bottom: 1.5em;
}

#alerts_module.homepage_contentbox.list_alerts .alerts li a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

#alerts_module.homepage_contentbox.list_alerts .alerts li .date {
	padding: 0;
	margin-bottom: 0;
	color: #0a706b;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
	display: block;
}

#alerts_module.homepage_contentbox.list_alerts .alerts .item_divider {
	display: none;
}

#alerts_module.homepage_contentbox.list_alerts .alerts li br {
	display: none;
}

#alerts_module.homepage_contentbox.list_alerts .alerts li .date {
	color: #0a706b;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
	display: block;
}

/* classifieds */
.home-content-section .classifieds-field a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section .classifieds-field a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

/* events */
#events_module.homepage_contentbox > br {
	display: none;
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 1.5em;
}

#events_module.homepage_contentbox .event_list table tr td.date {
	padding-top: 7px;
	color: #0a706b;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
}

#events_module.homepage_contentbox .event_list table tr td.title a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#events_module.homepage_contentbox .event_list table tr td.title a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

#events_module.homepage_contentbox .cms_description p {
	margin-bottom: 0;
}

#events_module.homepage_contentbox .footer {
	display: none;
}

@media (max-width: 991px) {
	#events_module.homepage_contentbox .event_list table tr td.date,
	#events_module.homepage_contentbox .event_list table tr td.title {
		display: block;
		width: 100%;
	}
}

/* files */
.home-content-section .files-collection .files-node {
	padding: 9px 0 0;
	margin: 0;
}

.home-content-section .files-field a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section .files-field a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

/* forums */
#forums_module.homepage_contentbox .cms_list_item {
	margin-bottom: 1.5em;
}

#forums_module.homepage_contentbox .cms_list_item h3 {
	margin-top: 0;
	margin-bottom: 0;
}

#forums_module.homepage_contentbox .cms_footer {
	display: none;
}

#forums_module.homepage_contentbox .cms_list_item a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#forums_module.homepage_contentbox .cms_list_item a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

/* ideas */
.home-content-section .ideas-field a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section .ideas-field a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

/* kudos */
.home-content-section .kudos-field a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section .kudos-field a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

/* links */
.home-content-section ul li a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section ul li a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

/* news */
#news_module.homepage_contentbox.displaymode_list > a {
	display: none;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin-top: 0;
	margin-bottom: 0;
}

#news_module.homepage_contentbox .cms_metadata2.cms_date h3 {
	line-height: 1.1rem;
}

/* partners */
#partners_module.homepage_contentbox.displaymode_list {
	margin-top: 15px;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
	margin-bottom: 1.5rem;
}

.home-content-section #partners_module.homepage_contentbox.displaymode_list .cms_title a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section #partners_module.homepage_contentbox.displaymode_list .cms_title a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

.home-content-section #partners_module.homepage_contentbox.displaymode_list .cms_list_item .cms_metadata1.cms_title .cms_content {
	color: #313131;
	font-size: 1.125rem;
	line-height: 1.6rem;
	display: block;
}

/* polls */

/* posts */
.home-content-section .posts-collection .posts-node {
	padding: 9px 0 0;
	margin: 0;
}

.home-content-section .posts-field:last-of-type {
	display: none;
}

.home-content-section .posts-field a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.home-content-section .posts-field a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

/* rates */

/* rss */
#feeds_module.homepage_contentbox .item a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#feeds_module.homepage_contentbox .item a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

#feeds_module.homepage_contentbox .item {
	background-position: 0px 7px;
}

#feeds_module.homepage_contentbox .item .date-published {
	color: #0a706b;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
	display: block;
}

#feeds_module.homepage_contentbox .view-feed-page {
	display: none;
}

/* submission forms */
#submissionforms_module.homepage_contentbox .cms_divider {
	display: none;
}

#submissionforms_module.homepage_contentbox label {
	display: inline;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	vertical-align: baseline;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.homepage_contentbox .cms_field input[type="text"],
#submissionforms_module.homepage_contentbox .cms_field input[type="tel"],
#submissionforms_module.homepage_contentbox .cms_field input[type="password"],
#submissionforms_module.homepage_contentbox .cms_field input[type="email"],
#submissionforms_module.homepage_contentbox .cms_field input[type="date"],
#submissionforms_module.homepage_contentbox .cms_field select,
#submissionforms_module.homepage_contentbox .cms_field textarea {
	width: 100%;
}

#submissionforms_module.homepage_contentbox .cms_field input.hasDatepicker[type="text"] {
	width: calc(100% - 20px);
}

#submissionforms_module.homepage_contentbox + script + .home-content-box-learn-more {
	display: none;
}

@media (max-width: 767px) {
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label label::after,
	#submissionforms_module.homepage_contentbox tr[data-required="true"] .cms_label div::after {
		content: "";
		padding: 0;
	}
}

/* testimonials */
#testimonials_module_contentbox.contentbox_item .company,
#testimonials_module_contentbox.contentbox_item .author-name {
	color: #0a706b;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: uppercase;
	margin-top: 10px;
	margin-bottom: 10px;
	display: block;
}

#testimonials_module_contentbox .w-slide-testimonial {
	height: auto;
	margin-bottom: 30px;
}

#testimonials_module_contentbox .image-box {
	margin-bottom: 10px;
}

.slider-widget #testimonials_module_contentbox.contentbox_item {
	text-align: left;
	margin: 20px;
}

/* videos */
.homepage-featured-video {
	float: none;
	clear: both;
	width: 100%;
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 25px;
	height: 0;
}

.homepage-featured-video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.homepage-featured-video .cbTitle {
	display: none;
}

.homepage-featured-video .moreVideos {
	display: none;
}

/* ecommerce  */
#products_module.homepage_contentbox.displaymode_list .cms_list_item_grid_container {
	width: 100%;
	display: block;
}

#products_module.homepage_contentbox.displaymode_list .cms_content {
	margin-bottom: 20px;
}

#products_module.homepage_contentbox.displaymode_list .cms_content p {
	margin-bottom: 0;
	margin-top: 0;
}

#products_module.homepage_contentbox.displaymode_list .metadata1.name {
	margin-top: 10px;
}

#products_module.homepage_contentbox.displaymode_list .metadata1.name a {
	margin-bottom: 0;
	margin-top: 0;
	padding-right: 0;
	padding-top: 0.125em;
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

.cms-ecommerce-slideshow-items .cms-slideshow-item .cms_title a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#products_module.homepage_contentbox.displaymode_list .metadata1.name a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

#products_module.homepage_contentbox.displaymode_list .cms_content span {
	background-color: transparent !important;
	display: inline;
}

#products_module.homepage_contentbox.displaymode_list .cms_content a {
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#products_module.homepage_contentbox.displaymode_list .cms_content a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}

#products_module.homepage_contentbox.displaymode_category_list > table > tbody > tr > td {
	width: 100%;
	display: block;
	padding-bottom: 15px;
}

#products_module.homepage_contentbox.displaymode_category_list .links {
	margin-top: 5px;
}

#products_module.homepage_contentbox.displaymode_category_list .links a {
	margin-bottom: 0;
	margin-top: 0;
	padding-right: 0;
	padding-top: 0.125em;
	color: #0a706b;
	font-size: 1.5rem;
	line-height: 2.1rem;
	font-weight: 400;
}

#products_module.homepage_contentbox.displaymode_category_list .links a:hover {
	color: rgba(10, 112, 107, 0.7);
	cursor: pointer;
}
